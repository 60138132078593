<template>
    <div>
        <h1>Privacy Policy</h1>
        <p>
            <i>Last updated May 11, 2023</i>
        </p>
        <p>
            This privacy notice for illi Engineering (<b>'Company'</b>, <b>'we'</b>, <b>'us'</b>, or <b>'our'</b>), describes how and why we might collect, store, use, and/or share (<b>'process'</b>) your information when you use our services (<b>'Services'</b>), such as when you:
        </p>
        <ul>
            <li>Download and use our smart television application (Mr illi), or use the website mijn.mrilli.nl</li>
            <li>Engage with us in other related ways, including any sales, marketing, or events</li>
        </ul>
        <p>
            Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at info@illi-tv.nl.
        </p>
        <h2>Table of contents</h2>
        <ol>
            <li>What information do we collect?</li>
            <li>How do we process your information?</li>
            <li>What legal bases do we rely on to process your personal information?</li>
            <li>When and with whom do we share your personal information?</li>
            <li>How long do we keep your information?</li>
            <li>How do we keep your information safe?</li>
            <li>Do we collect information from minors?</li>
            <li>What are your privacy rights?</li>
            <li>Controls for do-not-track features?</li>
            <li>Do we make updates to this notice?</li>
            <li>How can you contact us about this notice?</li>
            <li>How can you review, update, or delete the data we collect from you?</li>
        </ol>
        <h2>1. What information do we collect?</h2>
        <p>
            We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us. Personal Information Provided by You. The personal information we collect may include the following:
        </p>
        <ul>
            <li>Email addresses</li>
            <li>Usernames</li>
            <li>Passwords</li>
            <li>Video preferences</li>
            <li>Usage information</li>
        </ul>
        <p>We do not process any sensitive information.</p>
        <h2>2. How do we process your information?</h2>
        <p>
            We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
        </p>
        <ul>
            <li>
                To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.
            </li>
            <li>
                To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.
            </li>
            <li>
                To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.
            </li>
        </ul>
        <h2>3. What legal bases do we rely on to process your information?</h2>
        <p>
            We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests. The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
        </p>
        <ul>
            <li>
                Consent. We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time.
            </li>
            <li>
                Performance of a Contract. We may process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.
            </li>
            <li>
                Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
            </li>
            <li>
                Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
            </li>
        </ul>
        <h2>4. When and with whom do we share your personal information?</h2>
        <p>
            We may share information in specific situations described in this section and/or with the following third parties. We may need to share your personal information in the following situations: • Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
        </p>
        <h2>5. How long do we keep your information?</h2>
        <p>
            We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us. When we have no ongoing legitimate business need to process your personal information, we will delete such information.
        </p>
        <h2>6. How do we keep your information safe?</h2>
        <p>
            We aim to protect your personal information through a system of organisational and technical security measures. We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
        </p>
        <h2>7. Do we collect information from minors?</h2>
        <p>
            We do not knowingly collect data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at info@illi-tv.nl.
        </p>
        <h2>8. What are your privacy rights?</h2>
        <p>
            In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time. In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section <strong>'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</strong> below.
        </p>
        <p>
            We will consider and act upon any request in accordance with applicable data protection laws. If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority. If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.
        </p>
        <p>
            Withdrawing your consent: If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section <strong>'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</strong> below or updating your preferences. However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
        </p>
        <p>
            If you would at any time like to review or change the information in your account or terminate your account, you can contact us using the contact information provided. Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements. If you have questions or comments about your privacy rights, you may email us at info@illi-tv.nl.
        </p>
        <h2>9. Controls for do-not-track features</h2>
        <p>
            Most web browsers and some smart television operating systems and smart television applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
        </p>
        <h2>10. Do we make updates to this notice?</h2>
        <p>
            Yes, we will update this notice as necessary to stay compliant with relevant laws. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
        </p>
        <h2>11. How can you contact us about this notice?</h2>
        <p>
            If you have questions or comments about this notice, you may email us at info@illi-tv.nl or contact us by post at:<br>
            illi Engineering<br>
            Laan van Kronenburg 7<br>
            1183AS Amstelveen<br>
            The Netherlands
        </p>
        <h2>12. How can you review, update, or delete the data we collect from you?</h2>
        <p>You have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit: platform.illi-tv.nl or contact us at info@illi-tv.nl</p>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name: "privacyPolicy",
    components: {},
    data () {}
})
</script>